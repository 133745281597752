<template>
  <!-- 实名说明 -->
  <app-container :title="'实名说明'" :background="tool.getThemeList(theme)" @click-left="clickLeftHandle">
    <div class="auth_home">
      <div class="auth_content">
        <div class="explain">
          <div class="explain_desc">说明:</div>
          <div class="explain_text">
            1、为贯彻工信部《电话用户真实身份信息登记规定》的要求，卡片应与使用人一对应，必须办理实际使用人的实名登记。
          </div>
        </div>
        <div class="card_num">
          <span class="desc">卡号:</span>
          <div @click="focusCardInput" style="
          width: calc(100% - 158px);
          border: 1px solid transparent;
          height: 20px;
          position: absolute;
          right: 40px;">{{maskMiddleCharacters(card)}}</div>
          <input
            ref="cardInput"
            style="border: none;color: transparent;caret-color: rgba(0,0,0,.4);"
            type="text"
            placeholder="请输入卡号"
            v-model="card"
          />
        </div>
        <div class="underline"></div>
        <div class="choice" v-if="subCards.length <= 0 && iccid && (cardInfo.newReal != 1 || (cardInfo.newReal == 1 && passRealNameUrl))">
          <div class="desc_copy">请选择复制如下卡号</div> 
          <div class="desc_num">ICCID:{{ iccid }}</div>
        </div>
      </div>
      <div class="subCard">
        <div class="item" v-for="item in subCards" :key='item.cardId'>
          <div class="left">
            <p class="desc">{{tool.getOperatorType(item.operator)}}</p>
            <p class="val">{{item.iccid}}</p>
          </div>
          <div class="right">
            <van-button type="primary" size="small" :disabled="item.realNameStatus != 0" @click="querySubCardRealname(item.iccid)">{{item.realNameStatus == 0?'去实名':'已实名'}}</van-button>
          </div>
        </div>
      </div>               
        <button
          v-if="((!card && !iccid) || (card && !iccid) ) && subCards.length <= 0"
          class="submit_query"
          :disabled="!card.length"
          @click="getCardInfo"
        >
          查询
        </button>
        <button
          v-if="card.length > 0 && iccid.length > 0"
          class="submit_copy"
          :disabled="!card.length"
          :data-clipboard-text="cardInfo.newReal == 1 && !passRealNameUrl ? card:iccid"
        >
          已知晓，并复制卡号去实名
        </button>
    </div>
    <!-- 跳转小程序弹窗 -->   
    <van-popup v-model:show="realNameFlag" round closeable>
        <div class="realNameBox">
            <div class="realNameContent">
                <slot>
                    <div class="title">前往小程序实名</div>
                </slot>
                <slot>
                <div class="choice">
                    <div class="cancel" @click="realNameFlag = false">取消</div>
                    <div class="confirm">                        
                        <launch-btn v-if="realNameFlag && cardInfo.iccid" :btnText="'去实名'" :iccid="cardInfo.iccid.substring(0,19)"></launch-btn>
                    </div>                   
                </div>
                </slot>
            </div>
        </div>
    </van-popup>
  </app-container>
</template>

<script>
// 需要哪些模块按需引入
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { realNameUrl,querySubCardByMultiCardId ,queryCardInfo, pageNoShowIccidFetchRealUrl,getconf} from "_API_/api.services";
import { setStore, getStore, removeStore } from "@/utils/store";
import ClipboardJS from "clipboard";
import { urlAddParams } from '_UTIL_/utils.js'
import { isDevice } from "@/utils/utils";
import jssdk from '@/assets/js/jssdk.js'
import model from '@/utils/mobileModal'

export default {
  setup() {
    // 个人觉着还是这样写舒服一点 类似于vue2中的data
    const state = reactive({
      iccid: "", //查询出来的iccid
      mobile: "", //url 携带过来的 有就传给 新实名的 获取实名url 的接口
      backurl: "", //url 携带过来的 有就传给 新实名的 获取实名url 的接口
      card: "", //卡号
			sim:"",
      realNameLink: "", //通道链接
			realNameCourseLink:"", //实名教程链接
      theme: "", //主题名称
      subCards:[], // 多网卡子卡
      cardInfo:{},//卡信息 cardInfo.newReal == 1为ct-boss卡(不要展示iccid给用户看到)
      passRealNameUrl:"",//通道配置实名链接地址 
      realNameFlag: false
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
        clickLeftHandle () {                       
            let info = getStore({ name: 'miFiCard' }) || getStore({ name: 'anFangCard' }) || {}
            if(Object.keys(info).length > 0){                
                location.replace(info.backurl)                
            }                         
        },
      focusCardInput () {
        this.$refs.cardInput.focus();
      },
      maskMiddleCharacters(str) {
        if (str.length < 19) {
          return str;
        }
        
        const lastIndex = str.length - 1;
        const replacedStr = str.slice(0, lastIndex - 5) + '******';
        
        return replacedStr;
      },
        handleLaunchBtn () {
            return new Promise((resolve, reject) => {                     
                if (isDevice() == 'wx') {
                    console.log('sdk配置');
                    // 初始化jssdk的config
                    getconf({
                        url: location.href.split('?')[0],
                    }).then(({ data: res }) => {
                        if (res.code == 0 && !res.data.code) {                    
                            jssdk.config(res.data)      
                            resolve(true)                                                   
                        } else {
                            resolve(false)
                        }
                    })
                }
            })                    
        },
      //获取查询的卡号数据
      getCardInfo(info) {
        queryCardInfo(state.card, isDevice()).then(async ({ data: res }) => {
          if (res.code == 0) {
            state.cardInfo = res.data;
          }
            // 联通
            if(res.data.operator == 3 && isDevice() == 'wx'){
                state.realNameFlag =  await ctx.handleLaunchBtn()
            }
            if(!state.realNameFlag) ctx.queryRealNameUrl()          
        });
      },
      //查询是否有通道链接
      queryRealNameUrl() {
        Promise.all([querySubCardByMultiCardId(state.card),realNameUrl(state.card)]).then(([{data:res1},{data:res2}])=>{
          if(res1.code == 0 && res1.data && res1.data.multiCardIccid){
            let arr = res1.data.subCardList
						let sortArr = [],
								sortArr_ = []
						arr.map((item) =>{
							if(!Boolean(item.realNameStatus)){
								sortArr.push(item)
							}else{
								sortArr_.push(item)
							}
						})
						state.subCards = [...sortArr , ...sortArr_]
						return
          }
          // 判断isNewCTBossRealName字段 
          if(res2.code == 0 && res2.data && res2.data.isNewCTBossRealName == 1){            
            return location.href = res2.data.realNameUrl
          }
          // 判断是否走新的逻辑
          const params = {
            card: state.cardInfo.iccid,
            mobile: state.mobile, // 有就传没有就不传
            // backurl: state.backurl, // 有就传没有就不传
            backurl: state.backurl?state.backurl:window.location.origin + '/query?iccid=' + state.cardInfo.iccid 
          }
          console.log(params)
          pageNoShowIccidFetchRealUrl(params).then(({ data }) => {
            // 接口调用成功
            if (data.code == 0) {
              // 不满足条件走 - 原来的逻辑
              if (!data?.data?.isNewTelecomRealName) {
                return methods.realNameCb(res2)
              // 没手机号去绑定
              } else if (data?.data?.isNeedMobile == 1) {
                router.push({
                  name: 'personal-noShowIccidBindMobile',
                  query: {
                    card:  state.cardInfo.iccid,
                  }
                });
              } else if (data.data.realNameUrl) {
              // 满足上述 直接跳转
                location.href = data.data.realNameUrl
              } else {
                tool.toast({
                    msg: '未配置实名链接，请联系客服！'
                });    
              }
              return
            }
            // 错误提示
            tool.toast({
                msg: data.msg
            });
          });
        })
      },
      querySubCardRealname(card){
        realNameUrl(card).then(({data:res})=>{{
          if (res.code == 0 && res.data.realNameStatus == 1) {
            router.push({
              name: "realName-wait"
            });
            return;
          }
          // 若已经三要素实名，有通道链接 则可再次跳转通道链接
          if (
            res.code == 0 &&
            res.data.realNameStatus == 2 &&
            res.data.realNameUrl
          ) {
            tool.alert(
              {
                title: "提示",
                msg: `请长按卡号进行复制\n${res.data.iccid}\n然后前往实名`,
                confirmButtonText: "前往实名"
              },
              () => {
                // location.replace(res.data.realNameUrl+ `?card=${res.data.iccid}`)
                location.replace(urlAddParams(res.data.realNameUrl,`card=${res.data.iccid}`))
              }
            );
            return;
          }
          // 若已经三要素实名，没通道链接 则提示已实名
          if (res.code == 0 && res.data.realNameStatus == 2) {
            return tool.alert({
              title: "提示",
              msg: "用户已实名，无需再次实名"
            });
          }
          if (res.code != 0) {
            return tool.alert({
              title: "提示",
              msg: res.msg
            });
          }
          if (
            res.data.hasOwnProperty("errorMessage") &&
            res.data.errorMessage !== null
          ) {
            router.push({
              name: "realName-reject",
              query: {
                msg: res.data.errorMessage
              }
            });
            return;
          }
					// 若通道配置了运营商实名教程 则 跳转到对应的实名教程
					if(res.code == 0 && res.data.realNameCourse){
						setStore({
            	name: "realNameUrlInCourse",
            	content: res.data.realNameUrl || "",
            	type: "session"
          	});
						return location.href = res.data.realNameCourse +`${res.data.realNameCourse.indexOf('?')>-1?'&':'?'}` +'iccid=' + `${res.data.iccid}` + '&sim=' + `${res.data.sim}/`
					}
          tool.alert(
            {
              title: "提示",
              msg: `请长按卡号进行复制\n${res.data.iccid}\n然后前往实名`,
              confirmButtonText: "前往实名"
            },
            () => {
              // location.replace(res.data.realNameUrl+ `?card=${res.data.iccid}`)
              location.replace(urlAddParams(res.data.realNameUrl,`card=${res.data.iccid}`))
            }
          );
        }})
      },
      realNameCb(res){
        if (res.code == 0 && res.data.passRealNameUrl) {
          state.passRealNameUrl = res.data.passRealNameUrl
        }
        if (res.code == 0 && res.data.realNameStatus == 1) {
          router.push({
            name: "realName-wait"
          });
          return;
        }
				// 检测到有实名教程链接，则保存实名教程链接
				if (res.data && res.data.realNameCourse) {
          state.realNameCourseLink = res.data.realNameCourse;
        }
        if (res.code == 0) {
          state.iccid = res.data.iccid;
					state.sim = res.data.sim
        }
        // 若已经三要素实名，有通道链接 则可再次跳转通道链接
        if (
          res.code == 0 &&
          res.data.realNameStatus == 2 &&
          res.data.realNameUrl
        ) {
          tool.alert(
            {
              title: "提示",
              msg: "请复制卡号",
              confirmButtonText: "确定"
            },
            () => {
              state.iccid = res.data.iccid;
              state.realNameLink = res.data.realNameUrl;
            }
          );
          return;
        }
        // 若已经三要素实名，没通道链接 则提示已实名
        if (res.code == 0 && res.data.realNameStatus == 2) {
          return tool.alert({
            title: "提示",
            msg: "用户已实名，无需再次实名"
          });
        }
        if (res.code != 0) {
          return tool.alert({
            title: "提示",
            msg: res.msg
          });
        }
        if (
          res.data.hasOwnProperty("errorMessage") &&
          res.data.errorMessage !== null
        ) {
          router.push({
            name: "realName-reject",
            query: {
              msg: res.data.errorMessage
            }
          });
          return;
        }
        // 检测到有通道链接，则保存通道链接
        if (res.data && res.data.realNameUrl) {
          state.realNameLink = res.data.realNameUrl;
        }
        if(state.cardInfo.newReal == 1 && !state.passRealNameUrl){
          methods.toAuthStep()
        }
      },
      // 电信实名 - 页面不展示iccid 版本
      checkIsNewRealName () {
        pageNoShowIccidFetchRealUrl({
          card: state.cardInfo.iccid,
          mobile: state.mobile, // 有就传没有就不传
          // backurl: state.backurl, // 有就传没有就不传
          backurl: state.backurl?state.backurl:window.location.origin + '/query?iccid=' + state.cardInfo.iccid 
        }).then(({ data }) => {
          // 接口调用成功
          if (data.code == 0) {
            // 不满足条件走 - 原来的逻辑
            if (!data?.data?.isNewTelecomRealName) {
              return methods.toAuthStep()
            // 没手机号去绑定
            } else if (data?.data?.isNeedMobile == 1) {
              router.push({
                name: 'personal-noShowIccidBindMobile',
                query: {
                    card: state.cardInfo.iccid,
                  }
              });
            } else if (data.data.realNameUrl) {
            // 满足上述 直接跳转
              location.href = data.data.realNameUrl
            } else {
              tool.toast({
                  msg: '未配置实名链接，请联系客服！'
              });    
            }
            return
          }
          // 错误提示
          tool.toast({
              msg: data.msg
          });
        });
      },
      //去实名
      toAuthStep() {
        if(state.cardInfo.newReal == 1 && !state.passRealNameUrl){//ct-boss卡 且未配置通道实名链接
          location.href = urlAddParams(state.realNameLink,`card=${state.iccid}`)
          return
        }
				// 若通道配置了运营商实名教程 则 跳转到对应的实名教程
				if(state.realNameCourseLink){
					setStore({
            name: "realNameUrlInCourse",
            content: state.realNameLink || "",
            type: "session"
          });
          return location.href = state.realNameCourseLink +`${state.realNameCourseLink.indexOf('?')>-1?'&':'?'}` +'iccid=' + `${state.iccid}` + '&sim=' + `${state.sim}/`
				}
        //有实名链接
        if (state.realNameLink) {
          // location.href = state.realNameLink + `?card=${state.iccid}`;
          location.href = urlAddParams(state.realNameLink,`card=${state.iccid}`)
          return;
        }
        // 三要素实名
        router.push({
          name: "personal-authSystem"
        });
      },
      //检测路由参数
      judgeRoute() {
        if (route.query && route.query.card) {
          state.card = route.query.card;
          state.mobile = route.query.mobile;
          state.backurl = route.query.backurl;
        }
      },
      anFangGetCardInfo(card) {        
        queryCardInfo(card, isDevice()).then(async ({ data: res }) => {
            if (res.code == 0) {
                state.cardInfo = res.data;
                // 联通
                if (res.data.operator == 3) {
                    if(!getStore({name: 'anFangCard'})){
                        setStore({
                            name: 'anFangCard',            
                            content: { card, backurl: location.origin + '/af-platfrom' },
                            type: 'session'
                        })
                        // 调用函数，实现重定向
                        ctx.redirectUrlHandle()
                    }
                }                
            }                       
        });
      },
      mifiGetCardInfo(card) {
        queryCardInfo(card, isDevice()).then(async ({ data: res }) => {
            if (res.code == 0) {
                state.cardInfo = res.data;
                // 联通
                if (res.data.operator == 3) {
                    setStore({
                        name: 'miFiCard',            
                        content: { card, backurl: location.href.split('backurl=')[1] },
                        type: 'session'
                    })
                    // 调用函数，实现重定向        
                    ctx.redirectUrlHandle()
                }                
            }                       
        });
      },
      // 定义一个函数，用于实现重定向
      redirectUrlHandle(){        
        // 使用location.href属性实现重定向
        location.href = location.origin + '/personal-authenTication';        
      }
    };
    var clipboard = new ClipboardJS(".submit_copy");
    clipboard.on("success", e => {
      tool.toast(
        {
          msg: "卡号复制成功，即将进入实名",
          duration: 2500
        },
        // methods.toAuthStep
        methods.checkIsNewRealName
      );
      e.clearSelection();
    });
    clipboard.on("error", e => {
      tool.alert(
        {
          title: "提示",
          msg: `请先长按卡号复制\n${state.iccid}\n然后进入实名页面`,
          confirmButtonText: "已复制，立即实名"
        },
        // methods.toAuthStep
        methods.checkIsNewRealName
      );
    });
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      state.mobile = route.query.mobile;
      state.backurl = route.query.backurl;      
      if (Object.keys(info).length > 0) {
        state.card = info.virtualId || info.iccid;
        if (state.theme == 'AnFang') {
            return ctx.anFangGetCardInfo(state.card)
        }
      } else {
        methods.judgeRoute();
      }
      // 有手机号的话 直接进行查询操作
      if (state.mobile) {
        if(location.href.indexOf('mifi') > -1){
            return ctx.mifiGetCardInfo(route.query.card)            
        }        
        methods.getCardInfo()
      }
      // 从miFi带过来的卡
      if(getStore({name: 'miFiCard'})){        
        let info = getStore({name: 'miFiCard'}) || {};
        if(Object.keys(info).length > 0){
            state.card = info.card            
            methods.getCardInfo()
        }
      }  
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.auth_home {
  width: 690px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 40px;
  padding-bottom:80px;
  .auth_content {
    width: 630px;
    margin: 0 auto;
    padding-bottom: 30px;
    .card_num {
      width: 650px;
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      input {
        width: 430px;
        margin-right: 60px;
        color: #aaaaaa;
      }
    }
    .underline {
      width: 470px;
      height: 1px;
      background: #f2f2f2;
      margin: 0 150px;
    }
    .choice {
      width: 650px;
      height: 188px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      .desc_copy,
      .desc_num {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #777777;
      }
      .desc_num {
        color: #f14153;
      }
    }
    .explain {
      width: 650px;
      height: 188px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .explain_desc {
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .explain_text {
        font: {
          size: 26px;
          weight: 400;
        }
        color: #777777;
      }
    }
  }
  .subCard{
    .item{
      width: 97%;
      margin: 5px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 3%;
      background: #f7f7f7;
      box-sizing: border-box;
      border-radius: 5px;
      color: #666;
      p{
        margin: 0;
      }
      .desc{
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  .submit_query,
  .submit_copy {
    width: 690px;
    height: 84px;
    @include background_color("background_color1");
    box-shadow: 0px 8px 16px 0px #bce1ff;
    border-radius: 20px;
    border: none;
    font: {
      size: 30px;
      weight: 400;
    }
    color: #fbfbfb;
    text-align: center;
    line-height: 84px;
  }
  .submit_query[disabled] {
    @include background_color("background_color2");
  }
  .submit_copy {
    margin-top: 70px;
  }
}
.realNameBox {
  display: flex;
  flex-direction: column;
  width: 615px;
  margin: 0 auto;
  height: 300px;
  .appbar {
    // @include border-1px(#eeeeee, bottom);
    border-bottom: 1px solid #eeeeee;
    height: auto;
    .van-nav-bar__title,
    .van-nav-bar .van-icon {
      color: inherit;
    }
  }
  ::after {
    background: #2196f3 !important;
  }
  .realNameContent {
    flex: 1;
    overflow: auto;
    .title {
      background: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      text-align: center;
      height: 200px;
      line-height: 200px;
    }
    .choice {
      height: 98px;
      background: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      text-align: center;
      line-height: 98px;
      justify-content: center;
      .cancel {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: #f2f2f2;
        border-right-style: solid;
        border-right-width: 1px;
        width: 300px;
        color: #aaaaaa;
      }      
      .confirm {
        border-top-color: #f2f2f2;
        border-top-style: solid;
        border-top-width: 1px;
        border-left-color: #f2f2f2;
        border-left-style: solid;
        border-left-width: 1px;
        width: 300px;
        color: #0bb20c;
      }
    }
  }
}
</style>
